import React from 'react'
import NavbarGlobal from './NavbarGlobal'
import '../styles/szablon_globalny.css'

export default function HeaderGlobal() {
  return (
    <header className='header_gradient'>
       <NavbarGlobal />
    </header>
  )
}