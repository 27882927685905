import React from 'react'
import HeaderGlobal from './HeaderGlobal'
import FooterGlobal from './FooterGlobal'
import '../styles/szablon_globalny.css'
import '../styles/strona_404.css'
import '../styles/strona_blog.css'
import '../styles/strona_cennik.css'
import '../styles/strona_glowna.css'
import '../styles/strona_kontakt.css'
import '../styles/strona_kwalifikacje.css'
import '../styles/strona_wizyta.css'
import '../styles/strona_studio.css'
import '../styles/strona_o-mnie.css'
import '../styles/szablon_artykul.css'
import '../styles/szablon_dokumenty.css'
import '../styles/szablon_oferta.css'

export default function LayoutGlobal({ children }) {
  return (
    <div className='layout'>
      <HeaderGlobal />
        <div className='content'>
            { children }
        </div>
      <FooterGlobal />
    </div>
  )
}